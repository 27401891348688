import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import FmAlert from "../support/FmAlert";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextField from "../../components/formcontrols/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faShieldCheck, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { QRCodeSVG } from "qrcode.react";
import {
    ACCOUNT_SETUP_CONFIGURE_ACCOUNT,
    ACCOUNT_SETUP_SETUP_SMS_MFA,
    SEND_CODE_ROUTE,
    SUBMIT_CODE_ROUTE,
    useRunPreAuthApiMutation,
} from "../../api/api";
import { setOnboardingObject, setUserObject } from "../../app/slices/user/userSlice";
import { OnboardingObject } from "../../types/old_v1/types";
import { useStep } from "./AccountSetup";
import { validators } from "../../utils/validators";
import { setAccessToken, setRefreshToken } from "../../utils/token";
import { useNavigate } from "react-router-dom";

type OwnProps = {
    onboardingObject: OnboardingObject;
};

const EnterMfaCodeConnected = () => {
    const user = useAppSelector(({ user }) => user);
    const onboardingObject = user.onboardingObject;
    return <EnterMfaCode onboardingObject={onboardingObject} />;
};

/**
 * Page for inputting the MFA code and validates against the server.  If the user chooses to use SMS, a phone number input
 * is presented first to send the text message to.  If the MFA code is not good, an error message is displayed and advises starting
 * the process over.  If the user logs in and doesn't complete the MFA reconfigure in a reasonable amount of time, the temp jwt
 * provided for this process may expire in which case the user has to go back to the sign-in and start the process over.
 *
 * This component is used for both the new user onboarding process as well as the existing user MFA reconfigure option.  Slightly
 * different api endpoints are called for verifying the entered MFA code.
 * @param onboardingObject
 * @param user if successfully logg
 * @constructor
 */
const EnterMfaCode = ({ onboardingObject }: OwnProps) => {
    const [alert, setAlert] = React.useState<string | undefined>();
    const { setStep } = useStep();
    const [sendSmsCode, sendTextResponse] = useRunPreAuthApiMutation();
    const [readyForInput, setReadyForInput] = React.useState<boolean>(false);
    const [verifyCode, verifyCodeResponse] = useRunPreAuthApiMutation();
    const [verifyCodeOnboarding, verifyCodeOnboardingResponse] = useRunPreAuthApiMutation();
    const [mobileNumber, setMobileNumber] = React.useState<string>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Sends request to text the code
    const textCode = (phone: string) => {
        setMobileNumber(phone);
        if (onboardingObject.mfaChange) {
            sendSmsCode({
                mfaToken: onboardingObject.token,
                phone,
                email: onboardingObject.email,
                route: SEND_CODE_ROUTE,
            });
        } else {
            sendSmsCode({
                token: onboardingObject.token,
                phone_number: phone,
                mfa_type: "sms",
                route: ACCOUNT_SETUP_SETUP_SMS_MFA,
            });
        }
    };

    const submitMfaCode = (values: any) => {
        if (onboardingObject.mfaChange) {
            // onboarded user is changing mfa type
            verifyCode({
                isSMS: onboardingObject.mfa_type === "sms",
                mfaToken: onboardingObject.token,
                mfaCode: values.mfaCode.replace(/\s+/g, ""),
                route: SUBMIT_CODE_ROUTE,
            });
        } else {
            // on-boarding
            verifyCodeOnboarding({
                onboarding_token: onboardingObject.onboarding_token,
                token: onboardingObject.token,
                mfa_code: values.mfaCode.replace(/\s+/g, ""),
                mfa_type: onboardingObject.mfa_type,
                password: onboardingObject.password,
                route: ACCOUNT_SETUP_CONFIGURE_ACCOUNT,
            });
        }
    };

    // Initial check of the onboardingObject
    React.useEffect(() => {
        // if the authType is missing, something is wrong.  Stop!
        const authType = onboardingObject && onboardingObject.mfa_type;
        if (!authType || !onboardingObject.token || onboardingObject.complete) {
            setAlert("token_invalid");
        } else if (onboardingObject && !onboardingObject.complete) {
            const isReadyForInput = onboardingObject && onboardingObject.mfa_type === "app";
            setStep(3);
            setReadyForInput(isReadyForInput);
        }
    }, [onboardingObject, setStep]);

    // Listens for the text message send results from the server
    React.useEffect(() => {
        if (sendTextResponse.data) {
            if (sendTextResponse.data.error || !sendTextResponse.data.success) {
                setAlert(sendTextResponse.data.error || "text_failed");
            } else if (sendTextResponse.data.success) {
                setAlert(undefined);
                setReadyForInput(true);
            }
        }
    }, [sendTextResponse, setAlert]);

    // Had to add 2 listeners for the api calls because they are different at the moment
    React.useEffect(() => {
        if (verifyCodeResponse.isError) {
            setAlert("token_invalid_reconfigure_mfa");
        } else if (verifyCodeResponse.data) {
            setAccessToken(verifyCodeResponse.data.access);
            setRefreshToken(verifyCodeResponse.data.refresh);
            dispatch(setUserObject(verifyCodeResponse.data.user));
            if (!onboardingObject.complete) {
                dispatch(setOnboardingObject({ ...onboardingObject, complete: true }));
            }

            // Check the permissions from the back end user data instead of relying on redux to update
            if (verifyCodeResponse.data?.user?.permissions?.includes("use_nextfe_search")) {
                window.location.assign("/search/results/events");
            } else {
                navigate("/app/search");
            }
        }
    }, [verifyCodeResponse, setAlert, dispatch, onboardingObject, navigate]);

    // Had to add 2 listeners for the api calls because they are different at the moment
    React.useEffect(() => {
        if (verifyCodeOnboardingResponse.data) {
            if (verifyCodeOnboardingResponse.data.error || !verifyCodeOnboardingResponse.data.success) {
                setAlert(verifyCodeOnboardingResponse.data.error);
            } else if (verifyCodeOnboardingResponse.data.success) {
                setAccessToken(verifyCodeOnboardingResponse.data.access);
                setRefreshToken(verifyCodeOnboardingResponse.data.refresh);
                dispatch(setUserObject(verifyCodeOnboardingResponse.data.user));
                if (!onboardingObject.complete) {
                    dispatch(setOnboardingObject({ ...onboardingObject, complete: true }));
                }

                // Check the permissions from the back end user data instead of relying on redux to update
                if (verifyCodeOnboardingResponse.data?.user?.permissions?.includes("use_nextfe_search")) {
                    window.location.assign("/search");
                } else {
                    navigate("/app");
                }
            }
        }
    }, [verifyCodeOnboardingResponse, setAlert, dispatch, onboardingObject, navigate]);

    const loading =
        (verifyCodeResponse && verifyCodeResponse.isLoading) ||
        (verifyCodeOnboardingResponse && verifyCodeOnboardingResponse.isLoading) ||
        (sendTextResponse && sendTextResponse.isLoading);

    return (
        <div className="mt-4">
            <div className="d-flex justify-content-center mb-2">
                <h2>Enter Verification Code</h2>
            </div>
            {alert && (
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6} className="mt-2">
                        <FmAlert alertKey={alert} onClose={() => setAlert(undefined)} />
                    </Col>
                </Row>
            )}
            <>
                {onboardingObject && onboardingObject.mfa_type === "sms" && (
                    <Row className="justify-content-center">
                        <Col xs={11} sm={9} md={8} lg={6} xl={5}>
                            <Formik
                                initialValues={{ phone: "" }}
                                validateOnBlur={false}
                                onSubmit={(values) => {
                                    if (values && values.phone) {
                                        textCode(values.phone);
                                    }
                                }}
                                validationSchema={Yup.object({
                                    phone: validators.phone.required(),
                                })}
                            >
                                <Form>
                                    <Card>
                                        <Card.Header>Enter Mobile Phone Number</Card.Header>
                                        <Card.Body>
                                            <span>Enter a mobile phone number where the code will be sent by text message</span>
                                            <div className="d-flex justify-content-center">
                                                <Stack direction="horizontal" gap={3} className="mt-2">
                                                    <TextField label="Mobile Phone Number" name="phone" type="text" disabled={loading} />
                                                    <Button
                                                        size="sm"
                                                        type="submit"
                                                        variant="primary"
                                                        className="text-nowrap mt-4"
                                                        disabled={loading}
                                                    >
                                                        <FontAwesomeIcon icon={loading ? faSpinner : faPaperPlane} className="me-2" />
                                                        Send Text
                                                    </Button>
                                                </Stack>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Formik>
                        </Col>
                    </Row>
                )}
                {readyForInput && (
                    <Row className="justify-content-center mt-3">
                        <Col xs={11} sm={9} md={8} lg={6} xl={5}>
                            <Card>
                                <Card.Header>Enter Code</Card.Header>
                                <Card.Body>
                                    <Stack gap={3}>
                                        {mobileNumber && (
                                            <span>{`Input code sent to phone number ending in ${mobileNumber.slice(-4)}`}</span>
                                        )}

                                        {onboardingObject.mfa_type === "app" && (
                                            <Row className="justify-content-center">
                                                Enter the verification code from authenticator app
                                                <div className="d-flex justify-content-center">
                                                    <ul>
                                                        <li>In authenticator app, select scan QR code</li>
                                                        <li>Use your phone's camera to scan the QR code</li>
                                                        <li>Enter the code displayed in the app</li>
                                                    </ul>
                                                </div>
                                            </Row>
                                        )}
                                        {onboardingObject.mfa_type === "app" && onboardingObject.mfa_url && (
                                            <Row className="justify-content-center">
                                                <Col>
                                                    <div className="d-flex justify-content-center mb-3">
                                                        <QRCodeSVG value={onboardingObject.mfa_url || ""} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <div className="d-flex justify-content-center">
                                            <Formik
                                                initialValues={{ mfaCode: "" }}
                                                onSubmit={submitMfaCode}
                                                validateOnBlur={false}
                                                validationSchema={Yup.object({
                                                    mfaCode: Yup.string().required("Required"),
                                                })}
                                            >
                                                {(props) => (
                                                    <Form>
                                                        <Stack direction="horizontal" gap={3}>
                                                            <TextField
                                                                label="Verification Code"
                                                                name="mfaCode"
                                                                autoFocus={true}
                                                                disabled={loading}
                                                                showErrorText={false}
                                                            />
                                                            <Button
                                                                size="sm"
                                                                type="submit"
                                                                variant={loading || !props.dirty ? "secondary" : "success"}
                                                                className="text-nowrap mt-4"
                                                                disabled={loading || !props.dirty}
                                                            >
                                                                <FontAwesomeIcon icon={loading ? faSpinner : faShieldCheck} />
                                                                <span className="ms-2">Verify</span>
                                                            </Button>
                                                        </Stack>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Stack>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
            </>
        </div>
    );
};

EnterMfaCode.displayName = "EnterMfaCode";
export default EnterMfaCodeConnected;
