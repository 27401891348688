import React from "react";
import { faBinoculars, faFilter } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import TagDetails from "../../utils/TagDetails";
import { sort } from "../../utils/sort";
import { useSetPageTitle } from "../../hooks/useSetPageTitle";
import { Link, useSearchParams } from "react-router-dom";
import { useGetCountsByBeatsQuery, useGetTotalEventsCountQuery } from "../../api/api";
import { formatPercent } from "../../utils/number";
import ActivePeople from "./ActivePeople";
import TopLabels from "./TopLabels";
import OffensesHistogram from "./OffensesHistogram";
import InsightsMap from "./InsightsMap";
import IncidentsOverTime from "./IncidentsOverTime";
import TagsV2 from "../../components/tags/Tags";
import FilterBar, { getRangeLabel } from "../../components/modalFilters/FilterBar";
import { toUpperCase } from "../../utils/string";
import BeatsHistogram from "./BeatsHistogram";
import icon from "../../static/img/logo_white.png";
import printIcon from "../../static/img/logo.png";
import RadarSearch from "../../pages/radar/RadarSearch";
import { faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { useDarkMode } from "../../hooks/useDarkMode";
import FiltersModal from "../../components/modalFilters/FiltersModal";
import useSingleAgencyFilter from "../../hooks/useSingleAgencyFilter";
import { useMultiAgency } from "../../hooks/useMultiAgency";
import InsightsSpinner from "./insightsspinner/InsightsSpinner";
import { useFilters } from "../../hooks/useFilters";
import IncidentsByTimeBlock from "./IncidentsByTimeBlock";
import IncidentsByHour from "./IncidentsByHour";
import IncidentsByDay from "./IncidentsByDay";

export const calculateActiveTagPercent = (activeTagCount: number, totalEventCount: number) => {
    return !isNaN(totalEventCount) && !isNaN(activeTagCount) && totalEventCount !== 0 ? activeTagCount / totalEventCount : 0;
};

const ConnectedInsightsDashboard = () => {
    const [searchParams] = useSearchParams();
    const { queryParams } = useSingleAgencyFilter();
    const activeTags = searchParams.get("tags");
    const { beatsIsFetching, filterPayload, selectedBeats } = useFilters({ isSingleAgencyMode: true, includeGeometry: true });
    const labelOrEventTypeFilterApplied =
        !!queryParams.tags ||
        !!queryParams.incidentTypes ||
        !!queryParams.callTypes ||
        !!queryParams.callSources ||
        !!queryParams.callPriorities ||
        !!queryParams.offenses ||
        !!selectedBeats?.length;

    const beatsCountResponse = useGetCountsByBeatsQuery({ ...queryParams, filters: filterPayload }, { skip: beatsIsFetching });

    // total events count with date range filter and search query
    const {
        data: totalEventsData,
        isLoading: totalEventsDataLoading,
        isFetching: totalEventsDataFetching,
    } = useGetTotalEventsCountQuery({ query: queryParams.query, dateRange: queryParams.dateRange, filters: {} });

    // skip filtered events call if no labels | call types | incident types filters applied
    const {
        data: filteredEventsData,
        isLoading: filteredEventsDataIsLoading,
        isFetching: filteredEventsIsFetching,
    } = useGetTotalEventsCountQuery(
        { ...queryParams, filters: filterPayload },
        { skip: !labelOrEventTypeFilterApplied || beatsIsFetching }
    );

    let totalEventCount = null;
    let activeFilteredCount = null;
    let activeTagPercent = null;
    let offensesBuckets = null;
    let subTagBuckets = null;

    if (totalEventsData && !totalEventsDataLoading) {
        totalEventCount = totalEventsData?.aggregations?.total_event_count.value;
    }

    if (!filteredEventsDataIsLoading && !totalEventsDataLoading && !beatsIsFetching) {
        const responseToProcess = labelOrEventTypeFilterApplied ? filteredEventsData : totalEventsData;
        activeFilteredCount = responseToProcess?.aggregations?.total_event_count?.value;
        activeTagPercent = calculateActiveTagPercent(activeFilteredCount, totalEventCount);
        offensesBuckets = responseToProcess?.aggregations?.offenses?.buckets;
        subTagBuckets =
            responseToProcess?.aggregations?.tag_count?.buckets &&
            responseToProcess?.aggregations?.tag_count?.buckets.filter((bucket: any) => !activeTags?.includes(bucket.key));
    }

    return (
        <InsightsDashboard
            isFetching={filteredEventsIsFetching || totalEventsDataFetching}
            filteredEventsData={filteredEventsData}
            offensesBuckets={offensesBuckets}
            subTagBuckets={subTagBuckets}
            totalEventCount={totalEventCount}
            activeFilteredCount={activeFilteredCount}
            activeTagPercent={activeTagPercent}
            filterApplied={labelOrEventTypeFilterApplied}
            beatsCountResponse={beatsCountResponse}
        />
    );
};

type OwnProps = {
    isFetching: boolean;
    filteredEventsData: any;
    offensesBuckets: any;
    subTagBuckets: any;
    totalEventCount: number;
    activeFilteredCount: number;
    activeTagPercent: any;
    filterApplied: boolean;
    beatsCountResponse: any;
};

export const InsightsDashboard = ({
    isFetching,
    offensesBuckets,
    subTagBuckets,
    totalEventCount,
    activeTagPercent,
    activeFilteredCount,
    filterApplied,
    beatsCountResponse,
}: OwnProps) => {
    useSetPageTitle("ForceMetrics | Insights Dashboard");
    const { selectedAgency } = useSingleAgencyFilter();
    const { isMultiAgency } = useMultiAgency();
    const [searchParams] = useSearchParams();
    const dateRange = searchParams.get("dateRange");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const activeTags = searchParams.get("tags");
    const tags = [...TagDetails()].sort(sort("name", true));

    const selectedTags = tags.filter((t) => activeTags?.includes(t.code));

    const [show, setShow] = React.useState(false);

    const constructMainSearchLink = () => {
        const mainSearchParams = new URLSearchParams(searchParams);

        if (selectedAgency) {
            mainSearchParams.set("agencies", selectedAgency?.fm_uuid);
        }
        return `/app/search?${mainSearchParams}`;
    };

    const filteredEventsCount = isFetching ? 0 : activeFilteredCount || 0;

    const { isDarkMode } = useDarkMode();

    return (
        <Container style={{ marginTop: "1em" }} className="insights-dashboard-container" fluid>
            <Row>
                <h2 className="d-flex align-items-center text-nowrap">
                    <FontAwesomeIcon icon={faBinoculars} className="me-2" />
                    {`Insights Dashboard - ${dateRange !== "custom" ? `Last ` : ""} ${toUpperCase(
                        getRangeLabel(dateRange, startDate, endDate)
                    )} Overview`}
                </h2>
                <Col>
                    <Row className="d-flex align-items-center">
                        <Col md={8} className="mb-4">
                            <div className="align-self-center d-flex gap-4">
                                <div>
                                    <Button
                                        className="pendo_filters_modal_open_button cursor-pointer container-md justify-content-center d-inline-flex align-items-center gap-2 text-nowrap rounded sort-filter"
                                        onClick={() => setShow(true)}
                                    >
                                        <FontAwesomeIcon icon={faFilter} /> Filters
                                    </Button>
                                </div>
                                <FilterBar
                                    showModal={!show ? () => setShow(true) : null}
                                    tab={"insights"}
                                    showSingleAgencyFilter={isMultiAgency}
                                    isSingleAgencyMode
                                    includeBeatGeometry
                                />
                                <FiltersModal
                                    show={show}
                                    handleClose={() => setShow(false)}
                                    selectedTab={"insights"}
                                    resultsText="Events"
                                    defaultFilterValues={{ dateRange: "3years" }}
                                    defaultDateRange="all_time"
                                    totalSearchResults={filteredEventsCount}
                                    showSingleAgencyFilter={isMultiAgency}
                                    isSingleAgencyMode
                                    includeBeatGeometry
                                />
                            </div>
                        </Col>
                        <Col md={4} className="mb-4">
                            <RadarSearch />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <div className="mb-2">
                            {selectedTags && selectedTags.length ? (
                                <TagsV2 tags={selectedTags.map((tag) => tag.code)} />
                            ) : (
                                <span
                                    className="d-inline-block badge fw-normal me-1 mb-1 p-1 align-content-bottom text-black"
                                    style={{ backgroundColor: "rgba(128, 128, 128, 0.3)" }}
                                >
                                    <div className="mt-1 me-1">
                                        <img
                                            src={icon}
                                            alt="ForceMetrics Logo"
                                            style={{ height: "18px", filter: !isDarkMode ? "invert(100%)" : "invert(0%)" }}
                                            className="me-1 mt-n1 d-print-none"
                                        />
                                        <img
                                            src={printIcon}
                                            alt="ForceMetrics_Logo_printable"
                                            style={{ height: "18px", filter: !isDarkMode ? "invert(100%)" : "invert(0%)" }}
                                            className="me-1 mt-n1 d-none d-print-inline-block"
                                        />
                                        All Events
                                    </div>
                                </span>
                            )}
                        </div>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <Card>
                                <Card.Header>
                                    <Card.Title className="m-0" as="h3">
                                        Total Events
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className={`text-center ${!isFetching ? "fw-bold display-6 p-4" : ""}`}>
                                    {isFetching ? <InsightsSpinner /> : totalEventCount}
                                </Card.Body>
                            </Card>
                        </Col>
                        {filterApplied && (
                            <Col xs={4}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title className="m-0" as="h3">
                                            Filtered Events
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className={`text-center ${!isFetching ? "p-4" : ""}`}>
                                        {isFetching ? (
                                            <InsightsSpinner />
                                        ) : (
                                            <Link
                                                to={constructMainSearchLink()}
                                                target="_blank"
                                                className="d-flex justify-content-center"
                                                data-testid="filtered-events-link"
                                            >
                                                <span className="display-6 fw-bold me-1">{filteredEventsCount}</span>
                                                <FontAwesomeIcon icon={faUpRightFromSquare} size="1x" />
                                            </Link>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                        {filterApplied && (
                            <Col xs={4}>
                                <Card>
                                    <Card.Header>
                                        <Card.Title className="m-0" as="h3">
                                            Percent of Events
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className={`text-center ${!isFetching ? "fw-bold display-6 p-4" : ""}`}>
                                        {isFetching ? <InsightsSpinner /> : formatPercent(isFetching ? 0 : activeTagPercent)}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    <Row className="mt-3">
                        <Col lg={5}>
                            <InsightsMap beatsCountResponse={beatsCountResponse} />
                        </Col>
                        <Col lg={7}>
                            <Row>
                                <Col lg={4}>
                                    <BeatsHistogram beatsCountResponse={beatsCountResponse} />
                                </Col>
                                <Col lg={4}>
                                    <OffensesHistogram offensesBuckets={offensesBuckets} isFetching={isFetching} isClickable={false} />
                                </Col>
                                <Col lg={4}>
                                    <TopLabels tagBuckets={subTagBuckets} isFetching={isFetching} isClickable={false} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={6}>
                            <ActivePeople />
                        </Col>
                        <Col sm={6}>
                            <IncidentsOverTime />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <hr />
                            <h1>Last Week</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <IncidentsByTimeBlock />
                        </Col>
                        <Col sm={6}>
                            <IncidentsByHour />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <hr />
                            <h1>Last Year</h1>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={12}>
                            <IncidentsByDay />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default ConnectedInsightsDashboard;
