import { useAppSelector } from "../app/hooks";
import { ConstructedURLType } from "../types/types";
import { formatName } from "../utils/person";
import useNextAwarePathname from "./useNextAwarePathname";

/**
 * Yields the search params necessary for constructing address search URL
 * @param partialAddress
 * @returns
 */
const populateAddressSearchPageParams = (partialAddress: string): URLSearchParams => {
    const searchParams = new URLSearchParams();

    searchParams.append("query", partialAddress);
    searchParams.append("sort", "relevance");
    searchParams.append("selectedTab", "address");
    return searchParams;
};

/**
 * Yields the search params necessary for constructing phone number search URL
 * @param phoneNumber
 * @returns
 */
export const populatePhoneNumberSearchPageParams = (
    phoneNumber: string,
    canPerformNextFESearch: boolean,
    searchPathname: string
): string => {
    const searchParams = new URLSearchParams();

    searchParams.append("query", phoneNumber);

    if (!canPerformNextFESearch) {
        searchParams.append("sort", "relevance");
        searchParams.append("selectedTab", "person");
    }

    return `${searchPathname}?${searchParams}`;
};

/**
 * Yields the search params needed to construct URL for address entity page
 * @param address address doc
 * @param query
 * @returns
 */
const populateAddressEntityPageParams = (address: any, query: string | undefined | null): URLSearchParams => {
    const searchParams = new URLSearchParams();

    if (query) {
        searchParams.append("query", query);
    }
    if (address?.gps_lat && address?.gps_lon) {
        searchParams.append("gpsLat", address?.gps_lat);
        searchParams.append("gpsLon", address?.gps_lon);
    }

    const fullAddress = address?.full_address && address.full_address.trim();

    if (fullAddress && fullAddress.length) {
        searchParams.append("address", fullAddress);
    }

    return searchParams;
};

const populateMainSearchPersonParams = (person: any, params?: URLSearchParams) => {
    const query = params?.get("query");
    const newParams = new URLSearchParams();

    if (person.id || person.person_id) {
        if (query) {
            newParams.set("query", query);
        }
    } else {
        newParams.set("query", formatName(person));
    }

    return newParams;
};

export const useMultiAgency = () => {
    const { canPerformNextFESearch, canViewNextFEPersonDetails, mainSearchPath } = useNextAwarePathname();
    const user = useAppSelector(({ user }) => user.userObject);
    const isMultiAgency = !!user?.agency_is_multiagency;
    const parentAgencyUUID = user?.agency_uuid;

    // list of agency nicknames for agencies user is active in
    const userActiveAgenciesNicknames = !!user?.user_agencies
        ? user.user_agencies.filter((agency: any) => agency.active).map((agency: any) => agency.agency_shortname)
        : [];

    // list of current agency's sub agencies that user is active in
    const userSubAgencies: any[] = Object.values(user?.sub_agencies || {}).filter((agency: any) =>
        userActiveAgenciesNicknames.includes(agency.fm_nickname)
    );

    // filter should only show if multi agency and user is active in more than one sub agency
    const showMultiAgencyFilter = isMultiAgency && userSubAgencies.length > 1;

    /**
     * Grab the corresponding agencyId (aka nickname) for the given agency UUID
     * For single agency, yield the parent agency nickname
     * @param agencyUUID
     * @returns agency id
     */
    const getAgencyIdFromUUID = (agencyUUID: string | undefined) => {
        if (isMultiAgency && agencyUUID) {
            return userSubAgencies.find((agency: any) => agency.fm_uuid === agencyUUID)?.fm_nickname;
        }

        return user?.agency_shortname;
    };

    /**
     * Grab the corresponding UUID for the given agency agencyId (aka nickname), if multi-agency
     * For single agency, yield the parent agency UUID (agencyId is meaningless in this context)
     * @param agencyId
     * @returns agency UUID
     */
    const getAgencyUUID = (agencyId: string | undefined) => {
        if (!isMultiAgency) {
            return user?.agency_uuid;
        } else {
            if (!agencyId) {
                return user?.agency_uuid;
            } else {
                return user?.sub_agencies[agencyId]?.fm_uuid || user?.agency_uuid;
            }
        }
    };

    /**
     * Main driver for constructing entity page URLs
     * @param agencyId
     * @param entityPath
     * @param searchParams
     * @returns string
     */
    const constructMultiAgencyEntityPageUrl = (agencyId: string | undefined, entityPath: string, searchParams?: URLSearchParams) => {
        return `/app/${getAgencyUUID(agencyId)}/${entityPath}?${searchParams ? searchParams : ""}`;
    };

    /**
     * Constructs address entity page URL, with handling of constructing search URL if no GPS coords provided
     * @param agencyId
     * @param entity
     * @param query
     * @returns string
     */
    const constructAddressUrl = (agencyId: string | undefined, entity: any, query: string | undefined | null) => {
        const partialAddress = entity?.person_address_street;

        //If a partial address exists, but we don't have gps coords then reroute to search
        if (partialAddress && !entity?.gps_lat && !entity?.gps_lon) {
            return `/app/search?${populateAddressSearchPageParams(partialAddress)}`;
        } else {
            return constructMultiAgencyEntityPageUrl(agencyId, "address", populateAddressEntityPageParams(entity, query));
        }
    };

    /**
     * Constructs person entity page URL, with handling of constructing search URL if no person id is provided
     * @param agencyId
     * @param person
     * @param searchParams
     * @returns
     */
    const constructPersonUrl = (agencyId: string | undefined, person: any, searchParams?: URLSearchParams): ConstructedURLType => {
        if (!(person.id || person.person_id)) {
            const personParams = populateMainSearchPersonParams(person, searchParams);
            return {
                url: `${mainSearchPath}?${personParams}`,
                type: "search",
                isNextFELink: canPerformNextFESearch,
            };
        } else {
            const entityPath = `person/${person.id || person.person_id}`;

            return canViewNextFEPersonDetails
                ? {
                      url: `/details/${entityPath}/${getAgencyUUID(agencyId)}`,
                      type: "entity",
                      isNextFELink: true,
                  }
                : {
                      url: constructMultiAgencyEntityPageUrl(agencyId, entityPath),
                      type: "entity",
                      isNextFELink: false,
                  };
        }
    };

    /**
     * Constructs event entity page URL, handling case where call or incident number may not be present
     * @param agencyId
     * @param callNumber
     * @param incidentNumber
     * @param searchParams
     * @returns
     */
    const constructEventUrl = (
        agencyId: string | undefined,
        callNumber: string | undefined,
        incidentNumber: string | undefined,
        searchParams?: URLSearchParams
    ) => {
        const entityPath = `${callNumber ? `call/${callNumber}` : ""}${callNumber && incidentNumber ? "/" : ""}${
            incidentNumber ? `incident/${incidentNumber}` : ""
        }`;
        return constructMultiAgencyEntityPageUrl(agencyId, entityPath, searchParams);
    };

    return {
        isMultiAgency,
        parentAgencyUUID,
        userSubAgencies,
        showMultiAgencyFilter,
        getAgencyIdFromUUID,
        getAgencyUUID,
        constructMultiAgencyEntityPageUrl,
        constructAddressUrl,
        constructPersonUrl,
        constructEventUrl,
    };
};
